<template>
    <div class="menu" v-if="menu">
        <div class="my-2 text-center">
            <small>Configuration générale du menu</small>
            <div class="text-kalkin-1">
                {{ menu.name }}
            </div>
        </div>
        <FormMenu
            class="panel"
            :project-id="projectId"
            :menu="menu"
            :parent="menu.parent"
            @saved="$emit('saved')"
        />
        <template v-for="{ id, MenuDataSelection } in menu.DataSelections">
            <div class="my-2 text-center" :key="'title-' + id">
                <small>Configuration du lien avec la sélection</small>
                <div class="text-kalkin-1">
                    {{ getSelection(id).name }}
                </div>
            </div>
            <FormMenuDataSelection
                class="panel"
                :key="id"
                :project-id="projectId"
                :menu-id="menu.id"
                :selection-id="id"
                :data="MenuDataSelection"
                @saved="$emit('saved')"
            />
        </template>
    </div>
</template>

<script>
import FormMenu from "./form/FormMenu";
import FormMenuDataSelection from "./form/FormMenuDataSelection";

export default {
    components: {
        FormMenu,
        FormMenuDataSelection,
    },

    props: {
        projectId: [String, Number],
        menu: Object,
        selections: Object,
    },

    methods: {
        getSelection(id) {
            return this.selections[id] || { source: {} };
        },
    },
};
</script>

<style lang="scss" scoped>
.menu {
    display: flex;
    flex-direction: column;

    align-items: center;
    gap: 8px;
}

.panel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: calc(100% - 16px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;

    background: #fff;
}
</style>
