<template>
    <div
        :class="{
            'depth-gutter': depth > 0,
        }"
        v-show="visible"
    >
        <div
            class="arbo-node-block"
            :class="{
                selected,
                hover: dragHoverState,
                root: depth == 0,
                templated,
            }"
            @dragenter.stop="onDragEnter"
            @dragleave.stop="onDragLeave"
            @dragover.prevent
            @drop="onDrop"
            @click="selectMe"
        >
            <template v-if="depth == 0">
                <div>
                    Arborescence
                </div>
            </template>
            <template v-else>
                <template v-if="templated">
                    <div class="arbo-node-pill bg-kalkin-1">
                        <div>- Modèle -</div>
                    </div>
                </template>
                <div class="arbo-node-pill">
                    <div>Niveau {{ depth }}</div>
                </div>
                <div>
                    <div class="k-flex-row k-gap-m">
                        <small class="text-kalkin-2"> Nom du menu</small>
                        <small
                            class="rounded bg-kalkin-3 px-2 text-light"
                            v-show="menuType"
                        >
                            <b-icon-tag-fill /> {{ menuType }}
                        </small>
                    </div>
                    <div>
                        {{ menu.name }}
                    </div>
                </div>
                <div style="flex-grow:1" />
                <div>
                    <div v-if="menu.DataSelections.length > 0">
                        <div
                            class="item-selection"
                            v-for="s in menu.DataSelections"
                            :key="s.id"
                        >
                            <div>{{ getSelection(s.id).source.name }}</div>
                            <div class="k-flex-row">
                                {{ getSelection(s.id).name }}
                                <span
                                    v-show="getSelection(s.id).LangId"
                                    :class="`flag:${getSelection(s.id).LangId}`"
                                />
                            </div>
                            <div style="flex-grow:1" />
                            <div
                                v-b-tooltip.hover.right
                                :title="
                                    s.MenuDataSelection.filter
                                        ? 'Données filtrées'
                                        : 'Pas de filtre sur les données'
                                "
                            >
                                <b-icon-funnel-fill
                                    class="state-icon"
                                    :class="{
                                        active: s.MenuDataSelection.filter,
                                    }"
                                />
                            </div>

                            <b-icon-x-circle @click.stop="deleteAsso(s.id)" />
                        </div>
                    </div>
                    <div v-else-if="!hasChildren">
                        <small class="text-danger">
                            <b-icon-exclamation-triangle-fill />
                            Aucune donnée associée
                        </small>
                    </div>
                </div>
                <div style="width:5%" />
                <div class="state-menu">
                    <div
                        v-b-tooltip.hover.top
                        :title="isHidden ? 'Le menu est masqué' : ''"
                    >
                        <b-icon-eye-slash
                            class="state-icon"
                            :class="{ active: isHidden }"
                        />
                    </div>
                    <div v-b-tooltip.hover.top :title="menu.notes || ''">
                        <b-icon-file-text
                            class="state-icon"
                            :class="{ active: menu.notes }"
                        />
                    </div>
                    <div
                        v-b-tooltip.hover.bottom
                        :title="
                            ignore3D
                                ? 'Le contenu du menu ne sera pas visible sur la carte en naviguant dans les menus parents'
                                : ''
                        "
                    >
                        <b-iconstack
                            class="state-icon"
                            :class="{ active: ignore3D }"
                        >
                            <b-icon stacked icon="geo-alt" />
                            <b-icon stacked icon="x" scale="2" />
                        </b-iconstack>
                    </div>
                    <div
                        v-b-tooltip.hover.bottom
                        :title="menu.sort ? 'Tri personnalisé' : ''"
                    >
                        <b-icon-sort-down
                            class="state-icon"
                            :class="{ active: menu.sort }"
                        />
                    </div>
                </div>
            </template>
        </div>
        <div class="arbo-node-children" v-if="hasChildren">
            <div
                class="arbo-node-control pointer user-select-none"
                :class="{ expanded: showChildren }"
                v-if="menu.children.length"
                @click="showChildren = !showChildren"
            >
                <span v-show="!showChildren">
                    <b-icon-plus-square-fill /> Afficher les sous-menus masqués
                    (niveau {{ depth + 1 }} et plus)
                </span>
                <span v-show="showChildren">
                    <b-icon-dash-square-fill />
                </span>
            </div>

            <template v-for="m in menu.children">
                <ArboNode
                    :menu="m"
                    :selections="selections"
                    :key="m.id"
                    :depth="depth + 1"
                    :visible="showChildren"
                    :has-ancestor-hidden="isHidden"
                    :selected-menu-id="selectedMenuId"
                    :ignore3D="checkIgnore3D(m.id)"
                    @action="onAction"
                />
            </template>
        </div>
    </div>
</template>

<script>
import { get as _get } from "lodash";
import MenuData from "@/model/menu";

export default {
    name: "ArboNode",

    props: {
        menu: Object,
        selections: Object,
        depth: { type: Number, default: 0 },
        visible: { type: Boolean, default: true },
        hasAncestorHidden: { type: Boolean, default: false },
        ignore3D: { type: Boolean, default: false },
        selectedMenuId: [String, Number],
    },

    data: function() {
        return {
            hasChildren: this.menu.children && this.menu.children.length > 0,
            showChildren: true,

            dragHoverState: false,
        };
    },

    computed: {
        menuType() {
            return MenuData.getTypeLabel(
                this.menu.config && this.menu.config.type,
            );
        },

        selected() {
            return this.depth > 0 && this.selectedMenuId === this.menu.id;
        },

        templated() {
            return this.menu && this.menu.templateId;
        },

        isHidden() {
            if (this.depth > 0) {
                return this.hasAncestorHidden || !this.menu.isVisible;
            } else {
                return false;
            }
        },
    },

    watch: {
        menu: {
            handler() {
                this.hasChildren =
                    this.menu.children && this.menu.children.length > 0;
            },
            deep: true,
        },
    },

    methods: {
        onAction(payload) {
            this.$emit("action", payload);
        },

        selectMe() {
            if (this.depth > 0) {
                this.$emit("action", {
                    action: "SelectMenu",
                    data: this.menu.id,
                });
            }
        },

        deleteAsso(id) {
            if (this.depth > 0) {
                this.$emit("action", {
                    action: "RemoveSelectionFromMenu",
                    data: {
                        menuId: this.menu.id,
                        dataselectionId: id,
                    },
                });
            }
        },

        onDragEnter() {
            if (this.depth > 0) {
                this.dragHoverState = true;
            }
        },

        onDragLeave(event) {
            if (!event.currentTarget.contains(event.relatedTarget)) {
                this.dragHoverState = false;
            }
        },

        onDrop(event) {
            this.dragHoverState = false;
            if (this.depth > 0) {
                const selectionId = event.dataTransfer.getData("selectionId");
                this.$emit("action", {
                    action: "AddSelectionToMenu",
                    data: {
                        menuId: this.menu.id,
                        dataselectionId: selectionId,
                    },
                });
            }
        },

        getSelection(id) {
            return this.selections[id] || { source: {} };
        },

        checkIgnore3D(id) {
            const ignoremenu3D = _get(this.menu, "config.ignoremenu3D");

            if (Array.isArray(ignoremenu3D)) {
                return ignoremenu3D.includes(id);
            }
            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
.arbo-node-block.hover {
    border-color: $primary-color;
    background: #ccc;
}

.arbo-node-block * {
    user-select: none;
}

.item-selection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;

    min-height: 20px;
    margin: 2px 0;
    border: 1px solid $primary-color;
    border-radius: 8px;

    background: #eee;
    font-size: 12px;
}
.item-selection > :first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-self: stretch;

    padding: 0 8px;
    border-radius: 6px 0 0 6px;

    color: #fff;
    background: $primary-color;
}
.item-selection > :nth-child(2) {
    font-size: 12px;
}
.item-selection > :last-child {
    margin-right: 4px;

    color: #c00;
    font-size: 14px;
}
.item-selection > :last-child:hover {
    color: #f88;
    transform: scale(1.2);
    cursor: pointer;
}

.state-menu {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 0 3px;
}
.state-icon {
    color: #ddd;
}
.state-icon.active {
    color: #000;
}
</style>
