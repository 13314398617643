<template>
    <div>
        <b-form-group>
            <label> Tri des fiches </label>
            <b-form-select
                v-model="sort"
                :options="sortOptions"
                @change="feedSort"
            />
            <JSONTextArea
                v-model="sort"
                @validate="onValidate('isSortValid', $event)"
                @input="save"
            />
        </b-form-group>

        <b-form-group>
            <label>
                Regroupements des randonnées <small>(pack_mode)</small>
            </label>
            <b-form-select
                v-model="pack_mode"
                :options="packSortOptions"
                @change="feedPackMode"
            />
            <JSONTextArea
                v-model="pack_mode"
                @validate="onValidate('isPackModeValid', $event)"
                @input="save"
            />
        </b-form-group>

        <b-form-group>
            <label>
                Menu virtuel <small>(remplacement par les fiches)</small>
            </label>
            <b-form-checkbox
                class="k-switch"
                v-model="virtual"
                switch
                @input="save"
            >
                <small v-if="virtual">
                    <b-icon-exclamation-triangle-fill />
                    Le menu est virtuel
                </small>
                <small v-else>Le menu est classique</small>
            </b-form-checkbox>
        </b-form-group>

        <b-form-group v-if="menu.children.length > 0">
            <label> Affichage du contenu des sous-menus sur la carte </label>

            <div
                class="d-flex align-items-center"
                v-for="m in menu.children"
                :key="m.id"
            >
                <b-form-checkbox
                    class="k-switch"
                    :checked="getIgnore3D(m.id)"
                    size="sm"
                    switch
                    @input="setIgnore3D(m.id, $event)"
                />
                <b-icon-geo-alt v-if="getIgnore3D(m.id)" />
                <b-iconstack v-else>
                    <b-icon stacked icon="geo-alt" />
                    <b-icon stacked icon="x" scale="2" />
                </b-iconstack>
                <small>
                    {{ m.name }}
                </small>
            </div>
        </b-form-group>

        <small class="align-self-center mt-2 text-danger" v-show="!isValid">
            <b-icon-exclamation-circle /> Echec de la sauvegarde, au moins un
            champ est invalide
        </small>
    </div>
</template>

<script>
import JSONTextArea from "@/components/ui/item/JSONTextArea";

export default {
    components: {
        JSONTextArea,
    },

    props: {
        projectId: [String, Number],
        menu: Object,
        parent: Object,
    },

    data: function() {
        return {
            sortOptions: [
                {
                    html: "Pas de tri",
                    value: "",
                },
                {
                    html: "Par nom",
                    value: '["content.duration","name"]',
                },
                {
                    html: "Par durée",
                    value: '{"key_order":"duration"}',
                },
                {
                    html: "Par distance",
                    value: '{"key_order":"distance"}',
                },
            ],
            packSortOptions: [
                {
                    value: "",
                    html: "Pas de tri",
                },
                {
                    value: '{"key":"durationStr","pack_type":"duration"}',
                    html: "Par nom",
                },
                {
                    value: '{"key":"duration","pack_type":"duration"}',
                    html: "Par durée",
                },
                {
                    value: '{"key":"distance","pack_type":"distance"}',
                    html: "Par distance",
                },
            ],
            sort: JSON.stringify(this.menu.sort),
            pack_mode: JSON.stringify(
                (this.menu.config && this.menu.config.pack_mode) || null,
            ),
            virtual: (this.menu.config && this.menu.config.virtual) || false,

            ignoremenu3D:
                (this.menu.config && this.menu.config.ignoremenu3D) || [],

            isSortValid: true,
            isPackModeValid: true,
        };
    },

    computed: {
        isValid() {
            return this.isSortValid && this.isPackModeValid;
        },
    },

    watch: {
        menu: {
            handler() {
                this.sort = JSON.stringify(this.menu.sort);
                this.pack_mode = JSON.stringify(
                    (this.menu.config && this.menu.config.pack_mode) || null,
                );
                this.virtual =
                    (this.menu.config && this.menu.config.virtual) || false;
                this.ignoremenu3D =
                    (this.menu.config && this.menu.config.ignoremenu3D) || [];
            },
            deep: true,
        },
    },

    methods: {
        async feedSort() {
            var currentSelect = document.activeElement;
            currentSelect.value
                ? (this.sort = currentSelect.value)
                : (this.sort = "null");
            await this.save();
        },

        async feedPackMode() {
            var currentSelect = document.activeElement;
            currentSelect.value
                ? (this.pack_mode = currentSelect.value)
                : (this.pack_mode = "null");
            await this.save();
        },

        async onValidate(stateName, value) {
            this[stateName] = value;
        },

        getIgnore3D(id) {
            return !this.ignoremenu3D.includes(id);
        },

        async setIgnore3D(id, value) {
            if (!value) {
                if (!this.ignoremenu3D.includes(id)) {
                    this.ignoremenu3D.push(id);
                }
            } else {
                this.ignoremenu3D = this.ignoremenu3D.filter(v => v !== id);
            }
            await this.save();
        },

        async save() {
            if (this.isValid) {
                let config = this.menu.config;
                // Intégration du pack_mode modifié à la config
                if (this.pack_mode !== "null") {
                    config = config || {};
                    config.pack_mode = JSON.parse(this.pack_mode);
                } else if (config) {
                    delete config.pack_mode;
                }
                if (this.virtual) {
                    config = config || {};
                    config.virtual = true;
                } else if (config) {
                    delete config.virtual;
                }
                // Intégration du ignoremenu3D à la config
                if (this.ignoremenu3D.length > 0) {
                    config = config || {};
                    config.ignoremenu3D = this.ignoremenu3D;
                } else if (config) {
                    delete config.ignoremenu3D;
                }

                await this.$store.dispatch("arbo/updateMenu", {
                    projectId: this.projectId,
                    menuId: this.menu.id,
                    sort: this.sort,
                    config,
                });

                this.$emit("saved");
            }
        },
    },
};
</script>

<style></style>
