<template>
    <div class="my-1 user-select-none">
        <div class="header" v-b-toggle="`ac-${id}`">
            <b-icon-caret-down-fill class="header-caret" />
            <div><small>Filtrer par : </small>{{ name }}</div>
            <div
                v-show="!isValid || (!data.config && !hasChanged)"
                class="text-warning"
                v-b-tooltip.hover.v-warning
                title="Données invalides ou incomplètes. Sauvegarde impossible."
            >
                <b-icon-exclamation-triangle-fill />
            </div>

            <div class="header-menu-1" :class="{ opened: hasChanged }">
                <b-button
                    v-show="hasChanged"
                    size="xs"
                    variant="kalkin-1"
                    @click.stop="onSave"
                >
                    Enregistrer
                </b-button>
                <b-button
                    v-show="hasChanged"
                    size="xs"
                    variant="secondary"
                    @click.stop="onCancel"
                >
                    Annuler
                </b-button>
            </div>
            <div class="header-menu-2">
                <b-icon-chevron-left />
                <b-button size="xs" variant="danger" @click.stop="onDelete">
                    Supprimer le filtre
                </b-button>
            </div>
        </div>
        <b-collapse
            :id="`ac-${id}`"
            class="body"
            accordion="FormFilter"
            @show="collapsed = false"
            @hidden="collapsed = true"
        >
            <template v-if="!collapsed">
                <div class="description" v-if="description">
                    <b-icon-info-circle-fill /> {{ description }}
                </div>
                <GenericForm
                    class="p-2"
                    v-if="schema"
                    :schema="schema"
                    :model="localConfig"
                    @validated="isValid = $event"
                    @updated="onUpdate"
                />
                <div class="bg-danger text-light py-4" v-else>
                    Filtre obsolète ou invalide. Non supporté.
                </div>
            </template>
        </b-collapse>
    </div>
</template>

<script>
import GenericForm from "@/components/form-generator/GenericForm";
import Formatter from "@/tools/format";

export default {
    components: {
        GenericForm,
    },

    props: {
        id: [Number, String],
        filter: Object,
        data: Object,
    },

    data: function() {
        return {
            collapsed: true,
            isValid: true,
            hasChanged: false,
            localConfig: this.data.config || {},
        };
    },

    watch: {
        data() {
            this.localConfig = this.data.config || {};
        },
    },

    computed: {
        name() {
            return (this.filter && this.filter.form.label) || this.data.type;
        },

        description() {
            return this.filter && this.filter.form.description;
        },

        schema() {
            if (this.filter) {
                return {
                    ...Formatter.convertCOREtoFORM(this.filter.form.config),
                };
            } else {
                return false;
            }
        },
    },

    methods: {
        onUpdate(config) {
            this.localConfig = config;
            this.hasChanged = true;
        },

        onDelete() {
            this.$emit("delete");
        },

        onCancel() {
            this.localConfig = this.data.config || {};
            this.hasChanged = false;
        },

        onSave() {
            this.hasChanged = false;
            this.$emit("save", { ...this.data, config: this.localConfig });
        },
    },
};
</script>

<style lang="scss" scoped>
.header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    position: relative;

    width: 100%;
    padding: 4px 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
}

.header-menu {
    display: flex;
    align-items: center;
    gap: 5px;
    position: absolute;

    height: 100%;
    border-radius: 25px 6px 6px 25px;
    border-bottom-left-radius: 50% 100%;
    border-top-left-radius: 50% 100%;

    overflow: hidden;

    transition: width 0.3s ease-in-out;
}
.header-menu > button {
    box-shadow: none;
    white-space: nowrap;
}
.header-menu-1 {
    @extend .header-menu;
    right: 3px;
    width: 15px;
    padding-left: 10px;
    background-color: $secondary-color;
}
.header-menu-1.opened {
    width: 160px;
    border-bottom-left-radius: 6% 100%;
    border-top-left-radius: 6% 100%;
}
.header-menu-2 {
    @extend .header-menu;
    right: 0;
    width: 15px;
    background-color: #ccc;
}
.header-menu-2:hover {
    width: 160px;
    border-bottom-left-radius: 6% 100%;
    border-top-left-radius: 6% 100%;
}

.header-caret {
    transition: transform 0.4s;
}

.collapsed > .header-caret {
    transform: rotate(-90deg);
}

.body {
    margin: 0 6px;
    border: 1px solid #ccc;
    border-top: 0;
    border-radius: 0 0 6px 6px;

    background: #e5e5e5;
}
.description {
    padding: 5px;

    font-size: 0.85em;
    color: #888;
    font-style: italic;
    text-align: justify;
}
</style>
