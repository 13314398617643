<template>
    <div id="k-sp-container">
        <div id="k-sp-left" :class="`mode-${displayMode}`">
            <perfect-scrollbar class="h-100 w-100">
                <slot name="left" />
            </perfect-scrollbar>
        </div>
        <div id="k-sp-toolbar">
            <slot name="menu" />
            <div>
                <b-icon-layout-sidebar-reverse
                    :class="{ selected: displayMode == 0 }"
                    @click="displayMode = 0"
                />
                <b-icon-layout-split
                    :class="{ selected: displayMode == 1 }"
                    @click="displayMode = 1"
                />
                <b-icon-layout-sidebar
                    :class="{ selected: displayMode == 2 }"
                    @click="displayMode = 2"
                />
            </div>
        </div>
        <div id="k-sp-right" :class="`mode-${displayMode}`">
            <perfect-scrollbar class="h-100 w-100">
                <slot name="right" />
            </perfect-scrollbar>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        initialMode: { type: [String, Number], default: 0 },
    },

    data: function() {
        return {
            displayMode: this.initialMode,
        };
    },
};
</script>

<style lang="scss">
#k-sp-container {
    display: flex;
    align-items: center;

    height: 100%;
}

.k-sp-splitted {
    height: calc(100% - 10px);
    margin: 4px;
    border-radius: 6px;

    overflow: hidden;
    box-shadow: 0 0 6px #aaa;

    transition: width ease 0.8s;
}

#k-sp-left {
    @extend .k-sp-splitted;
}
#k-sp-right {
    @extend .k-sp-splitted;
}
#k-sp-left.mode-0 {
    width: 65%;
}
#k-sp-right.mode-0 {
    width: 35%;
}
#k-sp-left.mode-1 {
    width: 50%;
}
#k-sp-right.mode-1 {
    width: 50%;
}
#k-sp-left.mode-2 {
    width: 35%;
}
#k-sp-right.mode-2 {
    width: 65%;
}

#k-sp-toolbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    width: 30px;
    height: auto;
    margin: 4px;
    padding: 5px;
    border-radius: 6px;

    box-shadow: 0 0 6px #aaa;

    & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;

        & > * {
            width: 20px;
            height: 20px;
            padding: 2px;
            border: 1px solid#ccc;
            border-radius: 2px;

            background-color: #eee;
            color: $secondary-color;
            cursor: pointer;
        }

        & .selected {
            color: #111;
            cursor: unset;
        }

        & > hr {
            all: inherit;
            width: 100%;
            border-top: 1px solid $secondary-color;
            border-bottom: 1px solid #ccc;
        }
    }
}
</style>
