<template>
    <b-modal
        id="ModalConfirmDelete"
        header-text-variant="light"
        header-bg-variant="danger"
        body-text-variant="danger"
        title="Attention !"
        hide-header-close
        @shown="focusConfirmButton"
    >
        <div class="d-block text-center">
            Voulez-vous vraiment supprimer cet élément?
        </div>
        <template #modal-footer>
            <b-button variant="outline-secondary" @click="onCancelled">
                Annuler
            </b-button>
            <b-button ref="confirmButton" variant="danger" @click="onConfirmed">
                Confirmer
            </b-button>
        </template>
    </b-modal>
</template>
<script>
export default {
    props: {},
    methods: {
        onCancelled() {
            this.$bvModal.hide("ModalConfirmDelete");
            this.$emit("cancelled");
        },
        onConfirmed() {
            this.$bvModal.hide("ModalConfirmDelete");
            this.$emit("confirmed");
        },
        focusConfirmButton() {
            // Focus on the "Confirmer" button when the modal is shown
            this.$nextTick(() => {
                this.$refs.confirmButton.focus();
            });
        },
    },
};
</script>
