<template>
    <div>
        <label> Filtrage des données </label>
        <div class="accordion w-100">
            <template v-for="(f, i) in filter">
                <FormFilter
                    :id="i"
                    :data="f"
                    :filter="getFilterConfig(f)"
                    :key="i"
                    @save="onFilterSave(i, $event)"
                    @delete="onFilterDelete(i)"
                />
            </template>
        </div>
        <b-dropdown
            class="w-100"
            toggle-class="btn-dropdown-filter"
            menu-class="menu-dropdown-filter"
            size="sm"
            variant="outline-secondary"
        >
            <template slot="button-content" class="test">
                <b-icon-plus-circle /> Ajouter un filtre
            </template>
            <template v-for="f in publicFilters">
                <b-dropdown-item
                    :key="f.type + f.version"
                    @click="addFilter(f.type, f.version)"
                >
                    {{ f.form.label || f.type }}
                    <small>{{ `v.${f.version}` }}</small>
                </b-dropdown-item>
            </template>
        </b-dropdown>

        <label class="mt-2"> Configuration </label>
        <JSONTextArea
            v-model="config"
            @validate="onValidate('isConfigValid', $event)"
        />
        <small class="align-self-center mt-2 text-danger" v-show="!isValid">
            <b-icon-exclamation-circle /> Echec de la sauvegarde, au moins un
            champ est invalide
        </small>
    </div>
</template>

<script>
import JSONTextArea from "@/components/ui/item/JSONTextArea";
import FormFilter from "./FormFilter";
import { mapGetters } from "vuex";

export default {
    components: {
        JSONTextArea,
        FormFilter,
    },

    props: {
        projectId: [String, Number],
        menuId: [String, Number],
        selectionId: [String, Number],
        data: Object,
    },

    data: function() {
        return {
            filter: this.data.filter || [],
            config: JSON.stringify(this.data.config),

            isFilterValid: true,
            isConfigValid: true,
        };
    },

    computed: {
        isValid() {
            return this.isFilterValid && this.isConfigValid;
        },
        ...mapGetters("config", ["publicFilters", "allFilters"]),
    },

    watch: {
        data: {
            handler() {
                this.filter = this.data.filter || [];
                this.config = JSON.stringify(this.data.config);
            },
            deep: true,
        },
    },

    methods: {
        async addFilter(type, version) {
            this.filter.push({ type, version });
        },

        getFilterConfig(filter) {
            return this.allFilters.find(
                f => f.type === filter.type && f.version === filter.version,
            );
        },

        onFilterSave(index, filter) {
            this.filter[index] = filter;
            this.save();
        },

        onFilterDelete(index) {
            this.filter.splice(index, 1);
            this.save();
        },

        async onValidate(stateName, value) {
            this[stateName] = value;
            if (this.isValid) {
                await this.save();
            }
        },

        async save() {
            let filterToSave = this.filter.filter(f => f.config);
            if (filterToSave.length === 0) {
                filterToSave = null;
            }
            await this.$store.dispatch("datasource/updateMenuDataSelection", {
                projectId: this.projectId,
                menuId: this.menuId,
                dataselectionId: this.selectionId,
                config: this.config,
                filter: filterToSave,
            });
            this.$emit("saved");
        },
    },

    async beforeMount() {
        await this.$store.dispatch("config/getFilters");
    },
};
</script>

<style lang="scss" scoped>
.filter {
    border: 1px solid #ddd;
}
</style>

<style lang="scss">
.btn-dropdown-filter {
    background: #ccc;
}
.menu-dropdown-filter {
    width: calc(100% - 10px);
    margin: 0 5px;
    border-color: #6c757d;
}
</style>
