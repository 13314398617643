<template>
    <div class="container">
        <div class="text-center">
            <h4>Sources de données disponibles</h4>
            <small class="text-secondary font-italic">
                <b-icon-info-circle /> Faites glisser une sélection de données
                sur un menu pour l'ajouter à celui-ci
            </small>
        </div>
        <div class="text-right">
            <b-form-checkbox
                class="k-switch"
                v-model="hideUsed"
                size="sm"
                switch
            >
                Masquer les sélections déjà utilisées
            </b-form-checkbox>
        </div>
        <template v-for="source in sources">
            <div :key="source.id">
                <div
                    class="collapse-header"
                    v-b-toggle="`collapse-${source.id}`"
                >
                    <div class="collapse-arrow">
                        <b-icon-caret-up-fill />
                    </div>
                    <div class="text-center">
                        <small class="secondary">{{ source.type }}</small>
                        <div class="text-kalkin-1 font-weight-bold">
                            {{ source.name }}
                        </div>
                    </div>
                    <div>
                        <b-button
                            size="xs"
                            variant="outline-secondary"
                            @click.stop="goEditDatasource(source.id)"
                        >
                            <b-icon-gear />
                        </b-button>
                    </div>
                </div>

                <b-collapse
                    class="collapse-body"
                    :id="`collapse-${source.id}`"
                    visible
                >
                    <div>
                        <template v-for="id in source.selections">
                            <div
                                v-if="!hideUsed || !extract('usage', id)"
                                class="item-selection"
                                :key="id"
                                draggable="true"
                                @dragstart.stop="onDragStart($event, id)"
                            >
                                <div>{{ extract("name", id) }}</div>

                                <div class="k-flex-row">
                                    <span
                                        v-show="extract('LangId', id)"
                                        :class="`flag:${extract('LangId', id)}`"
                                    />
                                    Utilisée {{ extract("usage", id) }} fois
                                </div>
                            </div>
                        </template>
                    </div>
                </b-collapse>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        sources: Array,
        selections: Object,
        projectId: { type: [String, Number], required: true },
    },

    data: function() {
        return {
            hideUsed: false,
        };
    },

    methods: {
        extract(field, id) {
            if (this.selections[id]) {
                return this.selections[id][field];
            } else {
                return "erreur";
            }
        },

        onDragStart(event, id) {
            event.dataTransfer.setData("selectionId", id);
        },

        goEditDatasource(sourceId) {
            this.$router.push({
                name: "AdminDataSource",
                params: {
                    datasourceId: sourceId,
                    projectId: this.projectId,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    width: 100%;
    height: 100%;
    padding: 20px 8px;
}

.collapse-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border: 1px solid #ccc;
    border-radius: 5px 5px 0 0;
    position: relative;

    background: #ddd;
    transition: border-radius 0.3s ease;

    user-select: none;
}
.collapse-header.collapsed {
    border-radius: 5px;
}
.collapse-header > div {
    min-width: 40px;
}

.collapse-arrow {
    padding: 10px;
    color: #888;
    transform: rotate(180deg);
    transition: transform 0.3s;
}
.collapse-header.collapsed > .collapse-arrow {
    transform: rotate(90deg);
}

.collapse-body {
    border: 1px solid #ccc;
    border-top: 0;
    border-radius: 0 0 5px 5px;
    overflow: hidden;
    background: #fff;
}

.collapse-body > div {
    min-height: 20px;
}

.item-selection {
    display: flex;
    justify-content: space-between;

    padding: 5px 10px;
    margin: 7px;
    border: 1px solid $secondary-color;
    border-radius: 6px;

    color: #000;
    background: #fafffa;
    box-shadow: inset -1px -1px #888;
    text-align: left;
    font-size: 12px;
    cursor: move;
}
.item-selection:hover {
    background: #ded;
}

.item-selection * {
    user-select: none;
}
</style>
