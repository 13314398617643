var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],class:{
        'depth-gutter': _vm.depth > 0,
    }},[_c('div',{staticClass:"arbo-node-block",class:{
            selected: _vm.selected,
            hover: _vm.dragHoverState,
            root: _vm.depth == 0,
            templated: _vm.templated,
        },on:{"dragenter":function($event){$event.stopPropagation();return _vm.onDragEnter.apply(null, arguments)},"dragleave":function($event){$event.stopPropagation();return _vm.onDragLeave.apply(null, arguments)},"dragover":function($event){$event.preventDefault();},"drop":_vm.onDrop,"click":_vm.selectMe}},[(_vm.depth == 0)?[_c('div',[_vm._v(" Arborescence ")])]:[(_vm.templated)?[_vm._m(0)]:_vm._e(),_c('div',{staticClass:"arbo-node-pill"},[_c('div',[_vm._v("Niveau "+_vm._s(_vm.depth))])]),_c('div',[_c('div',{staticClass:"k-flex-row k-gap-m"},[_c('small',{staticClass:"text-kalkin-2"},[_vm._v(" Nom du menu")]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.menuType),expression:"menuType"}],staticClass:"rounded bg-kalkin-3 px-2 text-light"},[_c('b-icon-tag-fill'),_vm._v(" "+_vm._s(_vm.menuType)+" ")],1)]),_c('div',[_vm._v(" "+_vm._s(_vm.menu.name)+" ")])]),_c('div',{staticStyle:{"flex-grow":"1"}}),_c('div',[(_vm.menu.DataSelections.length > 0)?_c('div',_vm._l((_vm.menu.DataSelections),function(s){return _c('div',{key:s.id,staticClass:"item-selection"},[_c('div',[_vm._v(_vm._s(_vm.getSelection(s.id).source.name))]),_c('div',{staticClass:"k-flex-row"},[_vm._v(" "+_vm._s(_vm.getSelection(s.id).name)+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.getSelection(s.id).LangId),expression:"getSelection(s.id).LangId"}],class:("flag:" + (_vm.getSelection(s.id).LangId))})]),_c('div',{staticStyle:{"flex-grow":"1"}}),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",modifiers:{"hover":true,"right":true}}],attrs:{"title":s.MenuDataSelection.filter
                                    ? 'Données filtrées'
                                    : 'Pas de filtre sur les données'}},[_c('b-icon-funnel-fill',{staticClass:"state-icon",class:{
                                    active: s.MenuDataSelection.filter,
                                }})],1),_c('b-icon-x-circle',{on:{"click":function($event){$event.stopPropagation();return _vm.deleteAsso(s.id)}}})],1)}),0):(!_vm.hasChildren)?_c('div',[_c('small',{staticClass:"text-danger"},[_c('b-icon-exclamation-triangle-fill'),_vm._v(" Aucune donnée associée ")],1)]):_vm._e()]),_c('div',{staticStyle:{"width":"5%"}}),_c('div',{staticClass:"state-menu"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",modifiers:{"hover":true,"top":true}}],attrs:{"title":_vm.isHidden ? 'Le menu est masqué' : ''}},[_c('b-icon-eye-slash',{staticClass:"state-icon",class:{ active: _vm.isHidden }})],1),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",modifiers:{"hover":true,"top":true}}],attrs:{"title":_vm.menu.notes || ''}},[_c('b-icon-file-text',{staticClass:"state-icon",class:{ active: _vm.menu.notes }})],1),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],attrs:{"title":_vm.ignore3D
                            ? 'Le contenu du menu ne sera pas visible sur la carte en naviguant dans les menus parents'
                            : ''}},[_c('b-iconstack',{staticClass:"state-icon",class:{ active: _vm.ignore3D }},[_c('b-icon',{attrs:{"stacked":"","icon":"geo-alt"}}),_c('b-icon',{attrs:{"stacked":"","icon":"x","scale":"2"}})],1)],1),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],attrs:{"title":_vm.menu.sort ? 'Tri personnalisé' : ''}},[_c('b-icon-sort-down',{staticClass:"state-icon",class:{ active: _vm.menu.sort }})],1)])]],2),(_vm.hasChildren)?_c('div',{staticClass:"arbo-node-children"},[(_vm.menu.children.length)?_c('div',{staticClass:"arbo-node-control pointer user-select-none",class:{ expanded: _vm.showChildren },on:{"click":function($event){_vm.showChildren = !_vm.showChildren}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showChildren),expression:"!showChildren"}]},[_c('b-icon-plus-square-fill'),_vm._v(" Afficher les sous-menus masqués (niveau "+_vm._s(_vm.depth + 1)+" et plus) ")],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showChildren),expression:"showChildren"}]},[_c('b-icon-dash-square-fill')],1)]):_vm._e(),_vm._l((_vm.menu.children),function(m){return [_c('ArboNode',{key:m.id,attrs:{"menu":m,"selections":_vm.selections,"depth":_vm.depth + 1,"visible":_vm.showChildren,"has-ancestor-hidden":_vm.isHidden,"selected-menu-id":_vm.selectedMenuId,"ignore3D":_vm.checkIgnore3D(m.id)},on:{"action":_vm.onAction}})]})],2):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"arbo-node-pill bg-kalkin-1"},[_c('div',[_vm._v("- Modèle -")])])}]

export { render, staticRenderFns }