<template>
    <div>
        <b-icon-bar-chart-steps
            :class="{ selected: current == 0 }"
            v-b-tooltip.hover.left="'Editer la structure'"
            @click="onGoEditArbo(arbo.id)"
        />
        <b-icon-file-earmark-text
            :class="{ selected: current == 1 }"
            v-b-tooltip.hover.left="'Editer les données'"
            @click="onGoEditData(arbo.id)"
        />

        <b-dropdown class="k-drop" dropright variant="link" no-caret>
            <template #button-content>
                <b-icon-brush
                    class="w-100 h-100"
                    :class="{ selected: current == 2 }"
                    v-b-tooltip.hover.left="'Editer le style'"
                />
            </template>
            <b-dropdown-group
                header="Choix de la configuration"
                header-classes="text-kalkin-2"
            >
                <template v-if="arbo.ApplicationConfigs.length">
                    <b-dropdown-item
                        v-for="c in arbo.ApplicationConfigs"
                        :key="c.id"
                        @click="onGoEditStyle(arbo.id, c.id)"
                    >
                        {{ c.name }}
                    </b-dropdown-item>
                </template>
                <template v-else>
                    <b-dropdown-item disabled>
                        <i>Aucun configuration associée</i>
                    </b-dropdown-item>
                </template>
            </b-dropdown-group>
        </b-dropdown>

        <hr />
    </div>
</template>

<script>
export default {
    props: {
        projectId: { type: [String, Number], required: true },
        arbo: { type: Object, default: () => ({ ApplicationConfigs: [] }) },
        current: { type: [String, Number], default: -1 },
    },

    methods: {
        onGoEditArbo(id) {
            this.$router.push({
                name: "AdminEditArboStructure",
                params: {
                    arboId: id,
                    projectId: this.projectId,
                },
            });
        },
        onGoEditData(id) {
            this.$router.push({
                name: "AdminEditArboData",
                params: {
                    arboId: id,
                    projectId: this.projectId,
                },
            });
        },
        onGoEditStyle(id, config) {
            this.$router.push({
                name: "AdminEditArboStyle",
                params: {
                    arboId: id,
                    configId: config,
                    projectId: this.projectId,
                },
            });
        },
    },
};
</script>

<style lang="scss">
.k-drop {
    padding: 0 !important;
}
.k-drop > button {
    all: unset;
    display: flex;
    top: 0;
    width: 100%;
    padding: 2px;
}
</style>
