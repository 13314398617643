const DEFAULT_TYPE = "Menu classique";
const TYPES = {
    domaine: "Domaine skiable",
};

module.exports = {
    getTypeOptions() {
        return [
            { value: undefined, text: DEFAULT_TYPE },
            ...Object.entries(TYPES).map(([k, v]) => ({
                value: `"${k}"`,
                text: v,
            })),
        ];
    },

    getTypeLabel(id) {
        return TYPES[id];
    },
};
