<template>
    <SplitPanel initial-mode="0">
        <template v-slot:left>
            <TreeArboData
                :arbo="arboRoot"
                :selections="selections"
                :selected-menu-id="selectedMenuId"
                @action="onArboAction"
            />
        </template>
        <template v-slot:menu>
            <ToolboxArboMenu :projectId="projectId" :arbo="arbo" :current="1" />
        </template>
        <template v-slot:right>
            <div class="right-container">
                <div
                    class="panel panel-source"
                    :class="{ visible: !selectedMenuId }"
                >
                    <ListDataselection
                        :project-id="projectId"
                        :sources="sources"
                        :selections="selections"
                    />
                </div>
                <div
                    class="panel panel-selection"
                    :class="{ visible: selectedMenuId }"
                >
                    <PanelEditMenu
                        :project-id="projectId"
                        :menu="menus[selectedMenuId]"
                        :selections="selections"
                        @saved="fetchArbo"
                    />
                </div>

                <ModalConfirmDelete @confirmed="onDeleteConfirmed" />
            </div>
        </template>
    </SplitPanel>
</template>

<script>
import SplitPanel from "@/components/ui/SplitPanel";
import TreeArboData from "@/components/model/arbo/TreeArboData";
import ToolboxArboMenu from "@/components/model/arbo/ToolboxArboMenu";
import ListDataselection from "@/components/model/menu/ListDataselection";
import PanelEditMenu from "@/components/model/menu/PanelEditMenu";
import ModalConfirmDelete from "@/components/modal/ModalConfirmDelete";

export default {
    components: {
        SplitPanel,
        TreeArboData,
        ToolboxArboMenu,
        ListDataselection,
        PanelEditMenu,
        ModalConfirmDelete,
    },

    data: function() {
        return {
            projectId: this.$route.params.projectId,
            arboId: this.$route.params.arboId,

            arbo: undefined,
            arboRoot: null,
            menus: {},
            templateConfig: [],
            sources: [],
            selections: {},

            selectedMenuId: null,
        };
    },

    methods: {
        async fetchArbo() {
            const raw = await this.$store.dispatch("arbo/getMenus", {
                projectId: this.projectId,
                arboId: this.arboId,
                includeData: true,
            });

            this.menus = raw.reduce((acc, cur) => {
                cur.children = [];
                acc[cur.id] = cur;
                return acc;
            }, {});

            this.arboRoot = [];
            for (const menu of raw) {
                if (menu.parentId) {
                    this.menus[menu.parentId].children.push(menu);
                } else {
                    this.arboRoot.push(menu); //roots
                }
            }
        },

        async fetchData() {
            this.arbo = await this.$store.dispatch("project/getArbo", {
                projectId: this.projectId,
                arboId: this.arboId,
            });

            const raw = await this.$store.dispatch(
                "datasource/getDataSources",
                {
                    projectId: this.projectId,
                    includeSelections: true,
                },
            );
            this.sources = raw.reduce((acc, cur) => {
                acc.push({
                    id: cur.id,
                    name: cur.name,
                    type: cur.DataSourceType.name,
                    selections: cur.DataSelections.map(s => s.id),
                });
                return acc;
            }, []);
            this.selections = raw.reduce((acc, cur) => {
                for (const sel of cur.DataSelections) {
                    acc[sel.id] = { ...sel, source: cur, usage: 0 };
                }
                return acc;
            }, {});
            for (const menu of Object.values(this.menus)) {
                for (const { id } of menu.DataSelections) {
                    this.selections[id].usage++;
                }
            }
        },

        async onArboAction({ action, data }) {
            await this[`do${action}`](data);
        },

        async doSelectMenu(id) {
            const rightContainer = document.getElementById("k-sp-right")
                .children[0];
            rightContainer.scrollTop = 0;
            if (this.selectedMenuId !== id) {
                this.selectedMenuId = id;
            } else {
                this.selectedMenuId = null;
            }
        },

        async doAddSelectionToMenu({ menuId, dataselectionId }) {
            await this.$store.dispatch("datasource/associateDataSelection", {
                projectId: this.projectId,
                menuId,
                dataselectionId,
            });

            await this.fetchArbo();
            await this.fetchData();
        },

        async doRemoveSelectionFromMenu({ menuId, dataselectionId }) {
            await this.$store.dispatch("datasource/deleteMenuDataSelection", {
                projectId: this.projectId,
                menuId,
                dataselectionId,
            });

            await this.fetchArbo();
            await this.fetchData();
        },

        async doGoEditStructure() {
            this.$router.push({
                name: "EditArboStructure",
                params: {
                    arboId: this.arboId,
                    projectId: this.projectId,
                },
            });
        },

        async onDeleteConfirmed() {},
    },

    async beforeMount() {
        await this.fetchArbo();
        await this.fetchData();
    },
};
</script>

<style lang="scss" scoped>
.right-container {
    position: relative;
    height: 100%;
    width: 100%;
}

.panel {
    position: absolute;
    opacity: 0;

    height: auto;
    min-height: 100%;
    width: 100%;
    border-radius: 6px;
    padding-bottom: 10px;

    transition: opacity 0.6s, transform 0.6s;
}

.panel-source {
    background: #eee;
}

.panel-selection {
    background: #ddd;
    transform: translateX(-100%);
    border-left: 4px solid $secondary-color;
    border-right: 4px solid #bbb;
}

.visible {
    opacity: 1;
    transform: translateX(0);
}
</style>
